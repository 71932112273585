<script lang="ts" setup>
export type InputVariant = 'standard' | 'container' | 'reversed' | 'on-primary-bg' | 'on-accent-bg'
export type InputType = 'text' | 'password' | 'date' | 'number'

export interface InputProps {
  variant?: InputVariant
  type: InputType
  name: string
  label?: string
  hint?: string
  placeholder?: string
  tooltip?: string
  isRequired?: boolean
  readOnly?: boolean
  showStrength?: boolean | 'auto'
  modelValue?: any
  error?: string
  disabled?: boolean
  cypressPrefix?: string
  showPrefixDivider?: boolean
  isLoading?: boolean
}

const props = withDefaults(defineProps<InputProps>(), {
  variant: 'standard',
  type: 'text',
  showStrength: 'auto',
  disabled: false,
  showPrefixDivider: true,
  isLoading: false,
})

const emit = defineEmits(['update:modelValue', 'blur'])

if (!props.name)
  throw createError(`name is required (${props.label})`)

const modelValue = useVModel(props, 'modelValue', emit)

const { passwordRequirements } = useValidations()
const input = ref()

const hasStrengthIndicator = computed(() => {
  if (props.showStrength === 'auto')
    return props.type === 'password'
  return props.showStrength
})

function strength (input: string) {
  if (!input || !hasStrengthIndicator.value)
    return undefined

  const validCount = passwordRequirements(input).filter(req => req.valid).length

  return validCount === 4 ? 'great' : validCount === 3 ? 'good' : validCount === 2 ? 'medium' : validCount === 1 ? 'weak' : undefined
}

const [isPasswordVisible, togglePasswordVisibility] = useToggle(false)

const inputType = computed(() => {
  if (isPasswordVisible.value)
    return 'text'
  return props.type
})

const cypressName = computed(() => {
  return props.cypressPrefix ? `${props.cypressPrefix}-${props.name!}` : props.name!
})
</script>

<template>
  <div v-auto-animate flex flex-col gap-6px w-full>
    <label v-if="label && ['reversed', 'standard', 'on-primary-bg', 'on-accent-bg'].includes(variant)" :for="name" paragraph-md font-600 :class="['on-primary-bg', 'on-accent-bg'].includes(variant) ? 'text-black dark:text-white' : ''">
      {{ label }}
      <span text-error-default>{{ isRequired ? '*' : '' }}</span>
    </label>
    <div
      px-16px py-12px flex flex-col gap-4px
      border="1px solid"
      fubex-rounded-lg
      :class="[
        error ? 'border-error-default' : ' focus-within:fubex-focus-ring',
        disabled ? 'opacity-80 cursor-not-allowed bg-neutral-300!' : '',
        variant === 'standard' ? 'border-neutral-200 bg-neutral-50' : '',
        variant === 'reversed' ? 'bg-neutral-900 text-neutral-100 border-neutral-600' : '',
        variant === 'on-primary-bg' ? 'bg-primary-400 text-neutral-100 border-primary-400 dark:bg-primary-100 dark:border-primary-100 dark:text-white' : '',
        variant === 'on-accent-bg' ? 'bg-accent-400 text-neutral-900 border-accent-400 dark:bg-neutral-200 dark:border-neutral-200 dark:text-white' : '',
      ]"
      @click="input.focus()"
    >
      <h2 v-if="label && variant === 'container'" paragraph-md font-600>
        {{ label }}
        <span text-error-default>{{ isRequired ? '*' : '' }}</span>
      </h2>
      <div flex items-center justify-between gap-8px>
        <div flex items-center gap-8px w-full>
          <template v-if="!!$slots.prefix">
            <slot v-if="!!$slots.prefix" name="prefix" />
            <div v-if="showPrefixDivider" h-16px w-1px border="1px solid" />
          </template>
          <input ref="input" v-model="modelValue" :data-test="getCypressId(cypressName)" :disabled="disabled" :readonly="readOnly" :name="name" :type="inputType" bg-transparent paragraph-md focus:outline-none :placeholder="placeholder" w-full @blur="emit('blur')">
          <UnoIcon v-if="disabled" i-fubex-lock />
        </div>
        <UnoIcon v-if="error" i-carbon-warning w-16px h-16px text-error-default />
        <FLoadingDots v-else-if="isLoading" :is-loading="isLoading" />
        <div v-else-if="tooltip || !!$slots.suffix || type === 'date' || type === 'password'" flex gap-8px>
          <FTooltip v-if="tooltip" :text="tooltip">
            <UnoIcon i-carbon-help w-16px h-16px />
          </FTooltip>
          <UnoIcon v-if="strength(modelValue) && (strength(modelValue) === 'great' || strength(modelValue) === 'good')" i-carbon-checkmark-filled w-16px h-16px text-success-default />
          <UnoIcon v-if="type === 'date'" i-fubex-calendar w-16 h-16px @click="input.focus()" />
          <UnoIcon v-if="type === 'password'" :class="isPasswordVisible ? 'i-fubex-eye-crossed' : 'i-fubex-eye'" hover:cursor-pointer h-16px w-16px @click="togglePasswordVisibility()" />
          <template v-if="!!$slots.suffix ">
            <slot v-if="!!$slots.suffix" name="suffix" />
          </template>
        </div>
      </div>
    </div>
    <div v-if="error" :data-test="getCypressId('login-error')" text-error-default paragraph-md>
      {{ error }}
    </div>
    <FStrengthIndicator v-if="hasStrengthIndicator && !error && strength(modelValue)" :strength="strength(modelValue)!">
      <p v-if="hint" paragraph-md :class="[error ? 'text-error-default' : 'text-neutral-900']">
        {{ hint }}
      </p>
    </FStrengthIndicator>
    <p v-else-if="!error && hint" paragraph-md :class="[error ? 'text-error-default' : 'text-neutral-900']">
      {{ hint }}
    </p>
  </div>
</template>
