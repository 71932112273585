<script lang="ts" setup>
type StrengthType = 'weak' | 'medium' | 'good' | 'great'

const props = defineProps<{ strength: StrengthType }>()

const options: Record<StrengthType, { keyword: string, class: string }> = {
  weak: {
    keyword: 'Weak',
    class: 'error-default',
  },
  medium: {
    keyword: 'Medium',
    class: 'warning-default',
  },
  good: {
    keyword: 'Good',
    class: 'success-default',
  },
  great: {
    keyword: 'Great!',
    class: 'success-default',
  },
}

const classes = computed(() => {
  const color = props.strength ? options[props.strength].class : undefined
  if (!color)
    return undefined
  return {
    bg: `bg-${color}`,
    text: `text-${color}`,
  }
})

const currentStrengthIndex = computed(() => props.strength ? Object.keys(options).indexOf(props.strength) : undefined)
// unocss safe
// bg-error-default bg-warning-default bg-success-default
// text-error-default text-warning-default text-success-default
</script>

<template>
  <div pt-6px>
    <div v-auto-animate flex items-center gap-2px>
      <div v-for="(_, key) in options" :key="key" h-4px w-full fubex-rounded-lg transition-all duration-250 :class="[currentStrengthIndex! >= Object.keys(options).indexOf(key) ? classes?.bg : '']" />
    </div>
    <div flex justify-between items-center pt-6px>
      <p>
        <slot />
      </p>
      <h2 :data-test="getCypressId('strength-indicator')" paragraph-md :class="classes?.text">
        {{ options[strength]?.keyword }}
      </h2>
    </div>
  </div>
</template>
