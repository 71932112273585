import * as yup from 'yup'

interface DocumentValidationOptions {
  maxFiles?: number
  minFiles?: number
  required?: boolean
  allowedMimeTypes?: string[]
}

const emailValidation = yup.string().matches(/[\w.-]+@[\w.-]+\.\w{2,}/, 'Neplatný formát e-mailu').required('E-mail nebyl zadán')

const passwordValidation = yup.string().required('Zadejte heslo').min(8, 'Heslo musí mít alespoň 8 znaků')

const phoneValidation = yup.string().matches(/^(\+420|\+421)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/, { message: 'Neplatný formát telefonu', excludeEmptyString: true })

// IČO
const companyIdNumberValidation = yup.string().matches(/^\d{8}$/, 'IČO musí mít 8 číslic').required('IČO je povinné pole.')
// DIČ
const companyTaxIdNumberValidation = yup.string()
  .nullable()
  .matches(/^(|[a-zA-Z]{2,4}[0-9]{8,12})$/, 'Zadejte platné DIČ')

// IBAN
const ibanValidation = yup.string().uppercase().transform(value => value.replace(/\s+/g, ''))
  .matches(/^[A-Z]{2}[0-9]{2}[A-Z0-9]{1,30}$/, 'Neplatný formát IBAN')

// SWIFT/BIC
const swiftBicValidation = yup.string().uppercase().transform(value => value.replace(/\s+/g, ''))
  .matches(/^[A-Z]{4}[A-Z]{2}[A-Z0-9]{2}([A-Z0-9]{3})?$/, 'Neplatný formát SWIFT/BIC')

function fileValidation (options?: DocumentValidationOptions) {
  const {
    maxFiles,
    minFiles,
    required = false,
    allowedMimeTypes = ['image/jpeg', 'image/png', 'application/pdf'],
  } = options || {}

  let schema = yup.array()

  if (required)
    schema = schema.test('required', 'File is required', files => !!files?.length)

  return schema
    .test('maxFiles', `Upload max ${maxFiles} files`, files => {
      if (maxFiles && files && files.length > maxFiles)
        return false

      return true
    })
    .test('minFiles', `Upload at least ${minFiles} files`, files => {
      if (minFiles && files && files.length < minFiles)
        return false

      return true
    })

    .of(
      yup
        .mixed<File>()
        .test('fileType', 'Unsupported file type.', file => {
          if (!file || !file.type)
            return false

          return allowedMimeTypes.includes(file.type)
        })
        .test('fileSize', 'Max file size exceeded (4MB)', file => {
          if (!file)
            return true

          const maxSizeInBytes = 4 * 1024 * 1024 // 4 MB
          return file.size <= maxSizeInBytes
        }),
    )
}

function confirmPasswordValidation (passwordFieldName: string) {
  return yup.string().required('Confirm password is required').oneOf([yup.ref(passwordFieldName)], 'Passwords must match')
}

function passwordRequirements (input: string) {
  return [
    {
      text: 'Minimum 8 characters',
      valid: yup.string().min(8).required().isValidSync(input),
    },
    {
      text: 'One uppercase and lowercase character',
      valid: yup.string().matches(/[a-z]/).matches(/[A-Z]/).required().isValidSync(input),
    },
    {
      text: 'One number',
      valid: yup.string().matches(/[0-9]/).required().isValidSync(input),
    },
    {
      text: 'One special character',
      valid: yup.string().matches(/[!-/:-@[-`{-~]/).required().isValidSync(input),
    },
  ]
}

function cryptoAddressValidation (address: string, chain: string) {
  const cryptoAddresses = [
    { chain: 'Bitcoin', regex: /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,62}$/ },
    { chain: 'Ethereum', regex: /^0x[a-fA-F0-9]{40}$/ },
    { chain: 'Tron', regex: /^T[1-9A-HJ-NP-Za-km-z]{33}$/ },
    { chain: 'Monero', regex: /^[48][0-9A-Za-z]{94}$/ },
    { chain: 'Cardano', regex: /^addr1[0-9a-z]{98}$/ },
    { chain: 'Solana', regex: /^[1-9A-HJ-NP-Za-km-z]{44}$/ },
    { chain: 'Polkadot', regex: /^[1-9A-HJ-NP-Za-km-z]{47,48}$/ },
    { chain: 'Binance', regex: /^0x[a-fA-F0-9]{40}$/ },
    { chain: 'Arbitrum', regex: /^0x[a-fA-F0-9]{40}$/ },
    { chain: 'Matic', regex: /^0x[a-fA-F0-9]{40}$/ },
    { chain: 'Ripple', regex: /^r[1-9A-HJ-NP-Za-km-z]{25,34}$/ },
    { chain: 'Litecoin', regex: /^(L|M)[a-km-zA-HJ-NP-Z1-9]{26,34}$/ },
    { chain: 'Stellar Lumens', regex: /^G[A-DH-NP-Z1-9]{55}$/ },
    { chain: 'Dogecoin', regex: /^D[1-9A-Za-z]{25,34}$/ },
    { chain: 'Polygon', regex: /^0x[a-fA-F0-9]{40}$/ },
  ]
  const crypto = cryptoAddresses.find(crypto => crypto.chain === chain)
  if (!crypto)
    return false

  const regex = crypto.regex

  return regex.test(address)
}

export default function useValidations () {
  return {
    emailValidation,
    passwordValidation,
    phoneValidation,
    companyIdNumberValidation,
    companyTaxIdNumberValidation,
    ibanValidation,
    swiftBicValidation,
    fileValidation,
    confirmPasswordValidation,
    passwordRequirements,
    cryptoAddressValidation,
  }
}
